import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import { graphql } from 'gatsby';
import TeamMembers from '@interness/web-core/src/components/modules/TeamMembers';
import Lightbox from '@interness/web-core/src/components/media/Lightbox/Lightbox';
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../components/HeaderImg';
export const query = graphql`
  query {
    gallery: directusMediaCollection(name: {eq: "praxis"}) {
      name
        images: media {
          sort
          file {
            localFile {
              name
              childImageSharp {
                fluid(maxWidth: 1600, quality: 95) {
                  presentationWidth
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        thumbnails: media {
          sort
          file {
            localFile {
              name
              childImageSharp {
                fluid(maxWidth: 180, quality: 95) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="team-1" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Team`}</h1>
      <TeamMembers dropcap={false} mdxType="TeamMembers" />
      <hr></hr>
      <h2 {...{
        "id": "unsere-praxis"
      }}>{`Unsere Praxis`}</h2>
      <Lightbox images={props.data.gallery.images} thumbnails={props.data.gallery.thumbnails} thumbnailWidth='180px' fromApi mdxType="Lightbox" />
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      